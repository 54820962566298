import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/utils/utils"
import React from "react"

const LabelVariants = cva("font-medium leading-[1.2]", {
  variants: {
    size: {
      xsmall: "font-text text-xs xl:text-sm tracking-text",
      small: "font-text text-[0.9375rem] xl:text-base tracking-text",
      medium: "text-lg xl:text-xl tracking-text",
      large: "text-[1.375rem] xl:text-[1.5625rem] tracking-text"
    }
  },
  defaultVariants: {
    size: "small"
  }
})

export interface LabelProps extends React.ButtonHTMLAttributes<HTMLLabelElement>, VariantProps<typeof LabelVariants> {
  asChild?: boolean
}

const Label = React.forwardRef<HTMLLabelElement, LabelProps>(({ className, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "label"

  return <Comp className={cn(LabelVariants({ size, className }))} ref={ref} {...props} />
})

Label.displayName = "Label"

export { Label, LabelVariants }
