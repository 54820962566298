export const truncateString = (input: string, maxLength: number): string => {
  if (!input) return input
  if (input.length <= maxLength) {
    return input
  }

  const truncated = input.substring(0, maxLength) + "..."
  return truncated
}

export const truncateStringByWords = (input: string, maxWords: number): string => {
  if (!input) return input

  const words = input.split(" ")

  if (words.length <= maxWords) {
    return input
  }

  const truncatedWords = words.slice(0, maxWords)
  const truncated = truncatedWords.join(" ") + "..."

  return truncated
}
