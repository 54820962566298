/**
 * Prevent scroll hook.
 *
 * @param preventScroll boolean value that determines whether to prevent scroll or not
 */

import { useEffect } from "react"

export const usePreventScroll = (preventScroll: boolean) => {
  useEffect(() => {
    const initOverflow = document.body.style.overflow

    if (preventScroll) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }

    return () => {
      document.body.style.overflow = initOverflow
    }
  }, [preventScroll])
}
