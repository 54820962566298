import { cn } from "@utils/utils"
import { forwardRef, HTMLAttributes } from "react"

const defaultGrid = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12
}

type Size = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | number

type SizingProps = {
  xs?: Size
  sm?: Size
  md?: Size
  lg?: Size
  xl?: Size
}

interface ColProps extends HTMLAttributes<HTMLDivElement> {
  grid?: SizingProps
}

const Col = forwardRef<HTMLDivElement, ColProps>(({ children, className, grid = defaultGrid, ...props }, ref) => {
  const gridClasses = Object.entries(grid).map(([size, value]) => {
    if (size === "xs") {
      return `flex-${value} max-w-${value}`
    } else {
      return `${size}:flex-${value} ${size}:max-w-${value}`
    }
  })

  return (
    <div ref={ref} className={cn("my-2 block shrink grow px-4", gridClasses, className)} {...props}>
      {children}
    </div>
  )
})

Col.displayName = "Col"

export default Col
