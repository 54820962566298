import { useEffect } from "react"

/**
 * Hook that listens to window resize events and calls the provided callback.
 *
 * @param callback function to be called when the window is resized.
 */
export const useWindowResize = (callback: () => void) => {
  useEffect(() => {
    window.addEventListener("resize", callback)
    return () => {
      window.removeEventListener("resize", callback)
    }
  }, [callback])
}
