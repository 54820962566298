import {
  ArticleOverviewPageStoryblok,
  ArticlePageStoryblok,
  ClinicOverviewPageStoryblok,
  ClinicPageStoryblok,
  ContentPageStoryblok,
  FactAdviceOverviewPageStoryblok,
  FactAdvicePageStoryblok,
  ListingPageStoryblok,
  PageStoryblok,
  PatientfeesPageStoryblok,
  PressreleasePageStoryblok,
  VaccinationBusLocationPageStoryblok,
  VaccinationBusOverviewPageStoryblok
} from "@/types/component-types"
import { ISbStoryData } from "storyblok-js-client"
import { create } from "zustand"

export type StoryblokPageTemplates = ISbStoryData<
  | PageStoryblok
  | ClinicPageStoryblok
  | ClinicOverviewPageStoryblok
  | FactAdvicePageStoryblok
  | ArticlePageStoryblok
  | ArticleOverviewPageStoryblok
  | ContentPageStoryblok
  | FactAdviceOverviewPageStoryblok
  | ListingPageStoryblok
  | PatientfeesPageStoryblok
  | PressreleasePageStoryblok
  | VaccinationBusLocationPageStoryblok
  | VaccinationBusOverviewPageStoryblok
>

export type StoryStoreProps = {
  story: StoryblokPageTemplates | undefined
}

export const useStoryStore = create<StoryStoreProps>(() => ({
  story: undefined
}))
