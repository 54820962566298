export default function storyblokImageLoader({
  src,
  width = 0,
  height = 0,
  smart = false,
  quality
}: {
  src: string
  width: number
  height: number
  smart: boolean
  quality?: number
}) {
  if (!src.includes("a.storyblok.com")) {
    return `${src}?w=${width}`
  }

  if (src.includes(".svg")) {
    return `${src}?w=${width}`
  }

  return `${src}/m/${width}x${height}${smart ? "/smart" : ""}${quality ? `/filters:quality(${quality})` : ""}`
}
