import { Translations } from "@/translations/strings"
import { ClinicConfigStoryblok, ConfigStoryblok } from "@/types/component-types"
import { create } from "zustand"

export type ConfigStoreProps = {
  config: ConfigStoryblok | ClinicConfigStoryblok | null | undefined
  strings: Translations | null
  en: boolean
  enabledRegions?: string[]
}

export const useConfigStore = create<ConfigStoreProps>(() => ({
  config: null,
  strings: null,
  en: false,
  enabledRegions: []
}))
